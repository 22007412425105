export const getOrder = (
  orderMode,
  _orderList,
  _newOrderList,
  order,
  bookId,
  orderingNumbersByDeliveryRiderId,
) => {
  if (orderMode) {
    let newItem = _newOrderList.find((el) => el.bookId === bookId);
    return newItem
      ? newItem.order
      : orderingNumbersByDeliveryRiderId?.length > 0
      ? orderingNumbersByDeliveryRiderId[0]
      : "";
  } else {
    return order;
  }
};

export const getOrderWithOrderMode = (
  orderMode,
  _orderList,
  _newOrderList,
  order,
  bookId,
) => {
  if (orderMode) {
    let newItem = _newOrderList.find((el) => el.bookId === bookId);
    return newItem ? newItem.order : "";
  } else {
    return order;
  }
};

export const reOrder = (_orderList) => {
  let newList = [];
  _orderList.forEach((el, index) => {
    if (index > 0 && _orderList[index - 1].order === el.order) {
      newList.push({
        bookId: el.bookId,
        order: newList[newList.length - 1].order,
      });
    } else {
      newList.push({
        bookId: el.bookId,
        order: index + 1,
      });
    }
  });

  return newList;
};

export const getRearrange = (rearrangeMode, list, bookId) => {
  if (rearrangeMode) {
    let item = list.find((el) => el.bookId === bookId);

    return item ? "v" : "";
  } else {
    return "";
  }
};

/**
 * 전화번호 대시(-) 추가
 */
export const setMobileDash = (_mobile) => {
  let mobile = "";
  if (_mobile && _mobile.length > 10) {
    mobile =
      _mobile.substring(0, 3) +
      "-" +
      _mobile.substring(3, 7) +
      "-" +
      _mobile.substring(7);
  } else {
    mobile = _mobile;
  }

  return mobile;
};
